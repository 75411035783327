import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LockLineIcon from 'remixicon-react/LockLineIcon';
import { getLoginCredentials, postAPI, setLoginCredentials } from '../api/api.js';

function passwordStrength(pass) {
    let s = 1;
    if (/[A-Z]/g.test(pass) && /[a-z]/g.test(pass)) s++;
    if (/[0-9]/g.test(pass)) s++;
    if (/[^A-Za-z0-9]/gmi.test(pass)) s++;
    if (pass.length < 10) s--;
    if ([...new Set([...pass])].length < pass.length/2) s--;
    return { id: s };
}

function PasswordStrengthIndicator(params) {
    const s = params.id ?? 0;
    return <div className='d-flex justify-content-between align-content-stretch mt-1'>
        {[0, 1, 2, 3].map(k => {
            if (s > k) {
                return <div key={k} className='bg-primary bg-gradient rounded' style={{ width: '24%', padding: '2px', border: '1px solid rgba(0,0,0,0.5)' }}></div>;
            } else {
                return <div key={k} className='bg-light border rounded' style={{ width: '24%', padding: '2px', border: '1px solid lightgray' }}></div>;
            }
        })}
    </div>;
}

function FrontInvitation() {
    
    const [strength, setStrength] = useState(null);
    
    const checkPasswordStrength = e => {
        const s = passwordStrength(e.target.value);
        setStrength({
            ...s
        });
    }
    
    return <div className='container'>
        <div className='row justify-content-center'>
            <div className='col-sm-12 col-md-8 col-lg-6 col-xl-4'>
                
                <div className='mx-auto mt-4'>
                    <div className='card'>
                        <div className='card-body'>
                            
                            <h4><LockLineIcon/> Asiakastunnuksen aktivointi</h4>
                            <p>
                                Täällä voit aktivoida asiakastunnuksen. Oletusarvoisesti linkki sallii vain yhden tunnuksen luomisen.
                                Jos tarvitsette useampia tunnuksia, ilmoittakaa siitä asiakaspalveluun.
                            </p>
                            
                            <hr/>
                            
                            <form>
                                
                                <div className='mb-3'>
                                    <label>Asiakkaan tunniste</label>
                                    <input type='text' name='clientId' disabled={true} className='form-control form-control-sm'/>
                                </div>
                                
                                <div className='mb-3'>
                                    <label>Sähköpostiosoite</label>
                                    <input type='email' name='email' className='form-control form-control-sm'/>
                                </div>
                                
                                <div className='mb-3'>
                                    <label>Salasana</label>
                                    <input type='password' name='pass1' onChange={checkPasswordStrength} className='form-control form-control-sm'/>
                                    <PasswordStrengthIndicator id={strength?.id ?? 0}/>
                                </div>
                                
                                <div className='mb-3'>
                                    <label>Salasana uudelleen</label>
                                    <input type='password' name='pass2' className='form-control form-control-sm'/>
                                </div>
                                
                                <div className='mb-3'>
                                    <div className='form-check'>
                                        <input className='form-check-input' type='checkbox' value='acceptTerms' id='checkTerms'/>
                                        <label className='form-check-label' for='checkTerms'>
                                            Hyväksyn käyttöehdot ja tietosuojaselosteen
                                        </label>
                                    </div>
                                </div>
                                
                                <div>
                                    <button className='btn btn-primary btn-sm'><LockLineIcon/> Aktivoi tunnus</button>
                                </div>
                                
                            </form>
                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>;
    
}

export default FrontInvitation;