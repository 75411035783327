export function getApiBase() {
    if (window.location.host.indexOf(':') != -1) {
        return `http://${window.location.host.split(':')[0]}:8899/api`;
    } else {
        return `https://${window.location.host}/api`;
    }
}

export function setLoginCredentials(obj) {
    if (!window.localStorage) {
        throw new Error(`Session storage is not supported.`);
    }
    window.localStorage.setItem('__ld_license_session', JSON.stringify(obj));
}

export function getLoginCredentials() {
    if (!window.localStorage) {
        throw new Error(`Session storage is not supported.`);
    }
    const stored = window.localStorage.getItem('__ld_license_session');
    if (!stored) return null;
    return JSON.parse(stored);
}

export function isLoggedIn() {
    const credentials = getLoginCredentials();
    return credentials != null
        && Date.now() < credentials.expires;
}

export async function getAPI(path, respType = 'json') {
    const credentials = getLoginCredentials();
    const headers = {};
    if (credentials) {
        headers['X-LD-Token'] = credentials.token;
        headers['X-LD-UserId'] = credentials.userId;
    }
    const x = await fetch(`${getApiBase()}${path}`, { headers });
    if (!x.ok) throw new Error(`${await x.text()}`);
    switch (respType) {
        case 'json': return await x.json();
        case 'text': return await x.text();
        case 'blob': return await x.blob();
        case 'arrayBuffer': return await x.arrayBuffer();
    }
    return await x.json();
}

export async function postAPI(path, postData = {}, respType = 'json') {
    const credentials = getLoginCredentials();
    const headers = {
        'Content-Type': (typeof postData == 'string') ? 'text/plain' : 'application/json'
    };
    if (credentials) {
        headers['X-LD-Token'] = credentials.token;
        headers['X-LD-UserId'] = credentials.userId;
    }
    const x = await fetch(`${getApiBase()}${path}`, {
        method: 'POST',
        headers,
        body: (typeof postData == 'string') ? postData : JSON.stringify(postData)
    });
    if (!x.ok) throw new Error(`${await x.text()}`);
    switch (respType) {
        case 'json': return await x.json();
        case 'text': return await x.text();
        case 'blob': return await x.blob();
        case 'arrayBuffer': return await x.arrayBuffer();
    }
    return await x.json();
}