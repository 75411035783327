import React, { useState } from 'react';
import {
    HashRouter,
    Route,
    Routes
} from 'react-router-dom';

import FrontLogin from './front/FrontLogin.js';
import FrontInvitation from './front/FrontInvitation.js';
import FrontApp from './front/FrontApp.js';

function App() {
    return (
        <HashRouter>
            <Routes>
                <Route path='/' element={<FrontLogin/>}/>
                <Route path='/invitation/:invitationId' element={<FrontInvitation/>}/>
                <Route path='/app/*' element={<FrontApp/>}/>
            </Routes>
        </HashRouter>
    );
}

export default App;