import React, { Suspense, useState, useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getAPI, getLoginCredentials, isLoggedIn, postAPI, setLoginCredentials } from '../api/api.js';

import FolderAddLineIcon from 'remixicon-react/FolderAddLineIcon';

const AppClient = React.lazy(() => import('./app/AppClient.js'));
const AppClients = React.lazy(() => import('./app/AppClients.js'));
const AppLicense = React.lazy(() => import('./app/AppLicense.js'));
const AppLicenses = React.lazy(() => import('./app/AppLicenses.js'));
const AppBilling = React.lazy(() => import('./app/AppBilling.js'));
const RequireLogin = React.lazy(() => import('./RequireLogin.js'));
const AppStats = React.lazy(() => import('./app/AppStats.js'));
const AppOrders = React.lazy(() => import('./app/AppOrders.js'));
const AppQuotes = React.lazy(() => import('./app/AppQuotes.js'));
const AppQuote = React.lazy(() => import('./app/AppQuote.js'));
const AppOrder = React.lazy(() => import('./app/AppOrder.js'));
const AppOrderMail = React.lazy(() => import('./app/AppOrderMail.js'));

import AddCircleLineIcon from 'remixicon-react/AddCircleLineIcon';
import LineChartLineIcon from 'remixicon-react/LineChartLineIcon';
import RemixIcon from './util/RemixIcon.js';

function FrontNavbar() {
    return <nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
        <div className='container-fluid'>
            <a className='navbar-brand' href={isLoggedIn() ? `#/app` : `#/`}>LD⁴</a>
            <button className='navbar-toggler'
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className='navbar-toggler-icon'></span>
            </button>
            <div className='collapse navbar-collapse' id="navbarNav">
                <ul className='navbar-nav'>
                    <li className='nav-item'>
                        <Link to='/app' className='nav-link'><RemixIcon icon='ri-dashboard-3-fill'/> Dashboard</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/app/quotes' className='nav-link'><RemixIcon icon='ri-money-euro-circle-fill'/> Tarjoukset</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/app/clients' className='nav-link'><RemixIcon icon='ri-shield-user-fill'/> Asiakkaat</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/app/licenses' className='nav-link'><RemixIcon icon='ri-key-2-line'/> Lisenssit</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/app/orders' className='nav-link'><RemixIcon icon='ri-shopping-cart-2-fill'/> Tilaukset</Link>
                    </li>
                    {/*<li className='nav-item'>
                        <Link to='/app/billing' className='nav-link'>Laskutus</Link>
                    </li>*/}
                    <li className='nav-item'>
                        <Link to='/app/stats' className='nav-link'><RemixIcon icon='ri-bar-chart-2-fill'/> Tilastot</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>;
}

function AppDashboard() {
    
    return <div className='container mt-4'>
        <h3 className='ld-front-header'>Pikatoiminnot</h3>
        <div className='d-flex flex-wrap'>
            <Link to='/app/quote/new' className='ld-quick-wrapper'>
                <div className='ld-quick'>
                    <RemixIcon icon='ri-money-euro-circle-line'/>
                    Uusi tarjous
                </div>
            </Link>
            <Link to='/app/client/new' className='ld-quick-wrapper'>
                <div className='ld-quick'>
                    <FolderAddLineIcon/>
                    Lisää asiakas
                </div>
            </Link>
            <Link to='/app/license/new' className='ld-quick-wrapper'>
                <div className='ld-quick'>
                    <AddCircleLineIcon/>
                    Lisää lisenssi
                </div>
            </Link>
            <Link to='/app/stats' className='ld-quick-wrapper'>
                <div className='ld-quick'>
                    <LineChartLineIcon/>
                    Tilastot
                </div>
            </Link>
        </div>
        
        <h3 className='ld-front-header'>Poimintoja</h3>
        <FrontPicks/>
    </div>;
    
}

function FrontPicks(params) {
    
    const [errorText, setError] = useState(false);
    const [data, setData] = useState(null);
    
    useEffect(() => {
        (async () => {
            const stats = await getAPI('/getDashboardStats');
            if (stats.error) return setError(stats.error);
            setData(stats);
        })().catch(err => setError(err));
    }, []);
    
    if (errorText) {
        return <div className='alert alert-warning'>{errorText}</div>;
    }
    
    if (!data) {
        return <div>Haetaan...</div>;
    }
    
    return <div className='d-flex flex-wrap'>
        <div className='ld-front-figure-box'>
            <h1 className='ld-front-figure mb-0'>{data?.countLicenses ?? '?'}</h1>
            <h3 className='ld-front-figure-title mb-0'>aktiivista lisenssiä</h3>
        </div>
        <div className='ld-front-figure-box'>
            <h1 className='ld-front-figure mb-0'>{data?.countClients ?? '?'}</h1>
            <h3 className='ld-front-figure-title mb-0'>asiakasta</h3>
        </div>
        <div className='ld-front-figure-box'>
            <h1 className='ld-front-figure mb-0'>{data?.countStartsToday ?? '?'}</h1>
            <h3 className='ld-front-figure-title mb-0'>käynnistystä tänään</h3>
        </div>
    </div>;
    
}

function Loading() {
    return <div className='mt-4 p-4 text-center'>
        <div className='spinner-border' role="status">
            <span className='visually-hidden'>Loading...</span>
        </div>
        <p className='m-4'>Hetkinen...</p>
    </div>;
}

function FrontApp() {
    
    const navigate = useNavigate();
    
    if (!isLoggedIn()) {
        return <RequireLogin/>;
    }
    
    return <>
        
        <FrontNavbar/>
        
        <Suspense fallback={<Loading/>}>
            <Routes>
                <Route path='' element={<AppDashboard/>}/>
                <Route path='clients' element={<AppClients/>}/>
                <Route path='client/:id' element={<AppClient/>}/>
                <Route path='licenses' element={<AppLicenses/>}/>
                <Route path='license/:id' element={<AppLicense/>}/>
                <Route path='orders' element={<AppOrders/>}/>
                <Route path='order/:id' element={<AppOrder/>}/>
                <Route path='orderMail/:id' element={<AppOrderMail/>}/>
                <Route path='stats/*' element={<AppStats/>}/>
                <Route path='billing' element={<AppBilling/>}/>
                <Route path='quotes' element={<AppQuotes/>}/>
                <Route path='quote/:id' element={<AppQuote/>}/>
            </Routes>
        </Suspense>
        
        <p>&nbsp;</p>
        
    </>;
    
}

export default FrontApp;