import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginBoxLineIcon from 'remixicon-react/LoginBoxLineIcon';
import { getLoginCredentials, postAPI, setLoginCredentials } from '../api/api.js';

function FrontLogin() {
    
    const [user, setUser] = useState(''); 
    const [pass, setPass] = useState(''); 
    const [errorText, setError] = useState(false);
    const navigate = useNavigate();
    
    const onLogin = () => {
        (async () => {
            
            const loginResp = await postAPI(`/login`, { user, pass });
            if (loginResp.error) throw new Error(loginResp.content ?? loginResp.error);
            
            setLoginCredentials({
                userId: loginResp.userId,
                token: loginResp.token,
                timestamp: loginResp.timestamp,
                expires: loginResp.timeExpires
            });
            
            setUser('');
            setPass('');
            navigate('/app', { replace: true });
            
        })()
        .catch(err => {
            setError(`${`${err}`.substring(7)}`);
        })
    }
    
    return <div className='container'>
        
        <div className='ld-login mx-auto mt-4'>
            <div className='card'>
                <div className='card-body'>
                    
                    <form onSubmit={e => e.preventDefault()}>
                        
                        <div className='text-center'>
                            <img src='https://lehtodigital.fi/assets/ld.svg'
                                style={{ filter: 'invert(100%)', width: '100%', maxWidth: '150px' }}/>
                        </div>
                        <h5 className='text-center mb-0' style={{ fontSize: '18pt', fontWeight: '700' }}>Lisu<sup>4</sup></h5>
                        <h6 className='text-center'>Kirjaudu Sisään</h6>
                        <hr/>
                        
                        {errorText ? <div className='alert alert-warning'>{errorText}</div> : ''}
                        
                        <div className='mb-3'>
                            <label>Käyttäjä</label>
                            <input type='text'
                                name='user'
                                value={user}
                                onInput={e => setUser(e.target.value)}
                                spellCheck={false}
                                className='form-control'
                                />
                        </div>
                        
                        <div className='mb-3'>
                            <label>Salasana</label>
                            <input type='password'
                                name='pass'
                                value={pass}
                                onInput={e => setPass(e.target.value)}
                                spellCheck={false}
                                className='form-control'
                                />
                        </div>
                        
                        <div className='text-center'>
                            <button className='btn btn-primary btn-sm' onClick={onLogin}>
                                <LoginBoxLineIcon/> Kirjaudu
                            </button>
                        </div>
                        
                    </form>
                    
                </div>
            </div>
        </div>
        
    </div>;
    
}

export default FrontLogin;